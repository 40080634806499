import React, { useEffect } from 'react';
import { useAsyncRetry } from 'react-use';
import { useRxCollection } from 'rxdb-hooks';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import { CollectionName } from '../../database/types';
import { LessonDocType } from '../../database/schemas/lesson';
import _debounce from 'lodash/debounce';

type Props =
  | {
      classId: string;
      lessonId?: string;
    }
  | {
      classId?: string;
      lessonId: string;
    };

export function useFlashcardCount(props: Props) {
  const { classId, lessonId } = props;

  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );

  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );

  const { value: count, retry } = useAsyncRetry(async () => {
    let lessonsId = lessonId ? [lessonId] : [];

    if (classId && !lessonId && lessonCollection) {
      const lessonsQuery = lessonCollection.find({
        selector: {
          class_id: classId,
        },
      });

      const lessons = await lessonsQuery.exec();

      lessonsId = lessons.map(lesson => lesson._data.id);
    }

    if (flashCardCollection) {
      const flashcards = flashCardCollection.count({
        selector: {
          lesson_id: {
            $in: lessonsId,
          },
        },
      });

      const count = await flashcards.exec();
      return count || 0;
    }

    return undefined;
  }, [flashCardCollection, lessonCollection, classId, lessonId]);

  const debouncedRetry = _debounce(retry, 1000);

  useEffect(() => {
    if (flashCardCollection) {
      flashCardCollection.insert$.subscribe(debouncedRetry);
    }
  }, [flashCardCollection]);

  return count || 0;
}
