import { useCallback, useEffect, useRef, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { useDropzone } from 'react-dropzone';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';
import { RxDocument } from 'rxdb';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { exportFormatToFlashcard } from '../../utils/flashcard-export';
import { CollectionName } from '../../database/types';
import { ClassDocType } from '../../database/schemas/class';
import { LessonDocType } from '../../database/schemas/lesson';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import {
  AnswerType,
  FlashcardExport,
  QuestionType,
} from '../../types/FlashCard';
import { useFlashcardsLimit } from './useFlashcardsLimit';
import { supportedLngs } from '../../locales/i18n';

interface Props {
  classDocument?: RxDocument<ClassDocType>;
}

interface FlashcardWithClass extends FlashcardExport {
  class_id: string;
}

interface FlashcardWithLesson extends FlashcardWithClass {
  lesson_id: string;
}

const flashcardIsValid = (flashcard: FlashcardExport) => {
  const { class_name, lesson_name, class_language } = flashcard;
  const { question_type, question_value, answer_type, answer_value } =
    exportFormatToFlashcard(flashcard);

  const classNameInvalid = !class_name;
  const classLanguageInvalid = !supportedLngs.includes(`${class_language}`);
  const lessonNameInvalid = !lesson_name;
  const questionTypeInvalid =
    !Object.values(QuestionType).includes(question_type);
  const questionValueInvalid = !question_value;
  const answerTypeInvalid =
    question_type !== QuestionType.Spelling &&
    !Object.values(AnswerType).includes(answer_type);
  const answerValueInvalid =
    (answer_type === AnswerType.Text && !answer_value) ||
    (answer_value === AnswerType.MultipleChoice &&
      typeof answer_value !== 'object');

  if (
    classNameInvalid ||
    classLanguageInvalid ||
    lessonNameInvalid ||
    questionTypeInvalid ||
    questionValueInvalid ||
    answerTypeInvalid ||
    answerValueInvalid
  ) {
    return false;
  }

  return true;
};

export function useClassImport(props: Props = {}) {
  const { classDocument } = props;
  const [loading, setLoading] = useState(false);
  const classCollection = useRxCollection<ClassDocType>(CollectionName.Classes);
  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );
  const flashcardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );
  const reader = useRef(new FileReader());
  const { limit, getCount } = useFlashcardsLimit();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getFlashcardsWithClass = useCallback(
    async (
      flashcards: FlashcardExport[],
    ): Promise<{
      flashcardsWithClass: FlashcardWithClass[];
      classesToCreate: ClassDocType[];
    }> => {
      if (classDocument) {
        return {
          flashcardsWithClass: flashcards.map(flashcard => ({
            ...flashcard,
            class_id: classDocument.id,
          })),
          classesToCreate: [],
        };
      }

      const flashcardsByGroup = _groupBy(
        flashcards,
        ({ class_name, class_author, class_language }) =>
          `${class_name}-${class_author}-${class_language}`,
      );
      let flashcardsWithClass: FlashcardWithClass[] = [];

      const classesToCreate: ClassDocType[] = [];

      for (const groupKey in flashcardsByGroup) {
        const classFlashcards = flashcardsByGroup[groupKey];
        const { class_name, class_author, class_language } = classFlashcards[0];

        const classDoc = await classCollection
          ?.findOne({
            selector: {
              title: class_name,
              author: class_author,
              language: class_language,
            },
          })
          .exec();

        let class_id = classDoc?.id || uuidv4();

        if (!classDoc) {
          classesToCreate.push({
            title: class_name,
            author: class_author,
            language: class_language,
            id: class_id,
            shared: false,
          });
        }

        flashcardsWithClass = [
          ...flashcardsWithClass,
          ...classFlashcards.map(flashcard => ({
            ...flashcard,
            class_id,
          })),
        ];
      }

      return {
        flashcardsWithClass,
        classesToCreate,
      };
    },
    [classCollection, classDocument],
  );

  const getFlashcardsWithLesson = useCallback(
    async (
      flashcards: FlashcardWithClass[],
    ): Promise<{
      flashcardsWithLesson: FlashcardWithLesson[];
      lessonsToCreate: LessonDocType[];
    }> => {
      const flashcardsByGroup = _groupBy(
        flashcards,
        ({ class_id, lesson_name }) => `${class_id}-${lesson_name}`,
      );
      let flashcardsWithLesson: FlashcardWithLesson[] = [];
      const lessonsToCreate: LessonDocType[] = [];

      for (const groupKey in flashcardsByGroup) {
        const lessonsFlashcards = flashcardsByGroup[groupKey];
        const { class_id, lesson_name } = lessonsFlashcards[0];

        const lessonDoc = await lessonCollection
          ?.findOne({
            selector: {
              title: lesson_name,
              class_id,
            },
          })
          .exec();

        let lesson_id = lessonDoc?.id || uuidv4();

        if (!lessonDoc) {
          lessonsToCreate.push({
            id: lesson_id,
            class_id,
            title: lesson_name,
          });
        }

        flashcardsWithLesson = [
          ...flashcardsWithLesson,
          ...lessonsFlashcards.map(flashcard => ({
            lesson_id,
            ...flashcard,
          })),
        ];
      }

      return {
        flashcardsWithLesson,
        lessonsToCreate,
      };
    },
    [lessonCollection],
  );

  const importFlashcards = useCallback(
    async (flashcards: FlashcardExport[]) => {
      const { flashcardsWithClass, classesToCreate } =
        await getFlashcardsWithClass(flashcards);
      const { flashcardsWithLesson, lessonsToCreate } =
        await getFlashcardsWithLesson(flashcardsWithClass);

      await flashcardCollection?.bulkInsert(
        flashcardsWithLesson.map(({ class_id, lesson_id, ...flashcard }) => ({
          ...exportFormatToFlashcard(flashcard),
          class_id,
          lesson_id,
          id: uuidv4(),
        })),
      );
      await lessonCollection?.bulkInsert(lessonsToCreate);
      await classCollection?.bulkInsert(classesToCreate);
    },
    [classCollection],
  );

  const onFileRead = useCallback(
    async event => {
      setLoading(true);

      try {
        const count = await getCount();
        const dataParsed: FlashcardExport[] = Papa.parse(event.target.result, {
          header: true,
        }).data;

        const validFlashcards = dataParsed.filter(flashcardIsValid);
        const invalidFlashcardsCount =
          dataParsed.length - validFlashcards.length;

        if (count && limit && count + validFlashcards?.length >= limit) {
          return navigate(`/pricing?message=planLimitReached`);
        }

        if (
          invalidFlashcardsCount &&
          !window.confirm(t('message.flashcardsImportError'))
        ) {
          return;
        }

        await importFlashcards(validFlashcards);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 0);
      }
    },
    [
      classCollection,
      lessonCollection,
      flashcardCollection,
      classDocument,
      limit,
    ],
  );

  useEffect(() => {
    reader.current.addEventListener('load', onFileRead);
    return () => {
      reader.current.removeEventListener('load', onFileRead);
    };
  }, [onFileRead]);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      if (fileRejections[0]?.file?.size > 1048576) {
        alert('The file you are uploading is too large!');
      } else {
        alert('Sorry, something went wrong. Please try again later.');
      }
    } else {
      reader.current.readAsText(acceptedFiles[0]);
    }
  };

  const { open } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    onDrop,
    maxSize: 1048576,
  });

  return { importClass: open, loading };
}
